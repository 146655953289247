import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import jwtDecode from 'jwt-decode';
import {isNotLoginLogin} from '../../utils/session'

const LoginRoute = ({ component: Component, ...rest }) => {


  const isLoggedNotIn = isNotLoginLogin();

  return (
    <Route
    {...rest}
    render={props =>
      !isLoggedNotIn ? (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      ) : (
        <Component {...props} />
      )
    }
  />
  )
}

export default LoginRoute
